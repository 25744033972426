
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'DownloadBanner',
    data: () => ({
        hostname: null,
    }),
    computed: {
        ...mapState({
            downloadInterval: (state) => state.settings.downloadInterval,
        }),
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
    },
    mounted() {
        this.hostname = window.location.hostname
        // TODO: don't display Download Banner in Development Mode
        if (!this.$config.PRODUCTION) {
            this.$store.commit('settings/setDownloadInterval', false)
        }
    },
    methods: {
        closeBanner() {
            this.$store.commit('settings/setDownloadInterval', false)
        },
    },
}
