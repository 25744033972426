
import { mapGetters, mapState } from 'vuex'
import fingerprint from '~/mixins/fingerprint'
import ReCaptcha from '~/mixins/recaptcha'
import initSocket from '~/mixins/initSocket'
import register from '~/mixins/pages/register/register'

export default {
    name: 'Theme2Register',
    mixins: [fingerprint, ReCaptcha, initSocket, register],
    props: {
        toggle: {
            type: Function,
            default: () => {},
        },
        // to check if this component is called by Navbar or Register Page
        registerPage: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
        }),
        ...mapGetters({
            registrationImage: 'cms/registrationImage',
            loading: 'themes/player/loading',
            // supportCountries: 'themes/settings/supportCountry',
            // countries: 'themes/settings/countries',
        }),
    },
    // watch: {
    //     countries(value) {
    //         if (value && value?.length > 0) {
    //             const countryCode = this.$cookie?.get('country_code')
    //             const { code2 } = value[0]
    //             if (code2) {
    //                 this.formData.country_code = countryCode || code2
    //                 this.countryChange(countryCode || code2)
    //             }
    //         }
    //     },
    // },
    async mounted() {
        this.formData.reg_fingerprint = await this.visitorId()
        const countryCode = this.$cookie?.get('country_code')
        this.formData.country_code = countryCode
        this.countryChange(countryCode)
    },
    methods: {
        onLoginNow() {
            if (this.registerPage) {
                this.$router.push('/login')
            } else {
                this.toggle()
            }
        },
        onClose() {
            if (this.registerPage) {
                this.$router.push('/')
            } else {
                this.$emit('input', false)
            }
        },
        async register() {
            this.$refs?.refRegister?.validate()
            if (this.formValid && this.acceptCondition) {
                try {
                    this.registerLoading = true
                    this.formData.recaptchaToken = await this.getRecaptchaToken(
                        'register'
                    )

                    this.formData.registrationOption = this.registrationOption
                    const res = await this.$store.dispatch(
                        'themes/player/doRegister',
                        this.formData
                    )
                    if (res) {
                        // Delay WebSocket initialization to ensure previous operations are fully completed
                        setTimeout(async () => {
                            await this.initSocketTheme()
                        }, 1000)
                        this.$router.push('/player/deposits/apply')
                    } else {
                        this.resetRecaptcha()
                    }
                    this.registerLoading = false
                } catch (error) {
                    this.registerLoading = false
                }
            }
        },
    },
}
