
import { mapState, mapGetters } from 'vuex'
export default {
    data() {
        return {
            loginModal: false,
            registerModal: false,
        }
    },
    computed: {
        ...mapState({
            playGameLoading: (state) => state.settings.loading,
            player: (state) => state.player.player,
            isLogin: (state) => state.settings.isLogin,
            globalFlag: (state) => state.settings.globalFlag,
            supportCountryAndLanguage: (state) =>
                state.settings.supportCountryAndLanguage,
            onHoldWallet: (state) => state.player.onHoldWallet,
        }),
        ...mapGetters({
            languages: 'themes/settings/language',
            headerLogo: 'cms/headerLogo',
            exchangeRates: 'themes/settings/exchangeRate',
            supportCountries: 'themes/settings/supportCountry',
        }),
        isCapital() {
            let capital = 0
            const nickname = this.player.nickname
            if (!nickname) return false
            for (const letter of nickname) {
                if (this.checkCapitalLetter(letter)) {
                    capital += 1
                }
            }
            return capital >= 3
        },
        allowExchangeRateRequest() {
            const noThaiList = this.supportCountries.filter(
                (country) => country.country_code !== 'TH'
            )
            return noThaiList.length
        },
        countryFlag() {
            if (this.supportCountryAndLanguage?.length === 1) {
                return `flag-icon flag-icon-${this.supportCountryAndLanguage[0]?.country_code?.toLowerCase()}`
            } else {
                return `flag-icon flag-icon-${this.$cookie
                    .get('country_code')
                    ?.toLowerCase()}`
            }
        },
    },
    mounted() {
        if (this.$cookie.get('token')) {
            if (this.player.player_id) return
            this.$store.dispatch('player/profile')
            this.$store.dispatch('settings/toggleIsLogin', true)
            this.$store.dispatch('player/totalBalance')
        }
    },
    beforeDestroy() {
        this.doCancelAxiosRequest()
    },
    methods: {
        playGameNewTab() {
            this.$nuxt.$emit('openGameNewTab')
        },
        getCurrentLocale(localeCode) {
            const currentLocaleData = this.languages.filter(
                (el) => el.code === localeCode
            )
            if (currentLocaleData.length > 0) {
                return currentLocaleData[0]?.flag_icon
            }
            return null
        },
        openSignInModal() {
            this.registerModal = false
            this.loginModal = true
        },
        openSignUpModal() {
            this.loginModal = false
            this.registerModal = true
        },
    },
}
